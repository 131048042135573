import React, { useEffect, useState } from 'react';
import {Container} from "react-bootstrap";
import axiosConfig from "../axiosConfig";
import StudyPlanTable from "../components/StudyPlanTable";
import {BASections, MASections, PropedeuticsSections, sectionCode } from "../components/UsefulConstants";
import { ISectionInfo, IStudyPlan } from '../types';

export default function MyStudyplan() {
    const [sectionInfo, setSectionInfo] = useState<ISectionInfo>(undefined);
    const [studyPlan, setStudyPlan] = useState<IStudyPlan>(undefined); 
    const [isLoading, setIsLoading] = useState(true);
    const [userLevel, setUserLevel] = useState("");
    const [sectionFullName, setSectionFullName] = useState("");
    // initialize the page 
    useEffect(() => {
        axiosConfig.get("/section")
            .then((res) => {
                // needs to show section selection 
                if (res.data === undefined || Object.keys(res.data).length === 0){
                    setSectionInfo(undefined)
                    setIsLoading(false)
                } else {
                    // needs to set section directly 
                    setSectionInfo(res.data)
                    setSectionFullName(res.data.userInfo.sectionFullName)
                    setUserLevel(res.data.userInfo.userLevel)
                    // and then get grades 
                    axiosConfig.get("/studyplan")
                        .then((res) => {
                            console.log("study plan is ", res.data)
                            if (typeof res.data.creditTracker === "undefined") {
                             res.data.creditTracker = {}
                            }
                            if (typeof res.data.GPATracker === "undefined") {
                             res.data.GPATracker = {}
                            } 
                            setStudyPlan(res.data)
                            setIsLoading(false)
                        })
                }
                } 
            )
    }, [])

    // in case the user changes level or section, get a new list of section courses
    // and save user's information in userSchema 
    const changeSection = async () => {
        console.log("change section called")
        if (sectionFullName === "" || userLevel === "") {
            alert("Please select a section and level")
            return 
        }
        // send to the route with newly chosen section information 
        // accessed in the backend by res.query
        axiosConfig.get("/section",  { params:{
            sectionFullName: sectionFullName,
            userLevel: userLevel
            }  
        })
            .then((res) => {
                console.log("Res is ", res)
                setSectionInfo(res.data)
                setSectionFullName(res.data.userInfo.sectionFullName)
                setUserLevel(res.data.userInfo.userLevel)
                setStudyPlan({ creditTracker: {}, GPATracker: {} })
                console.log("study plan is now ", studyPlan)
                setIsLoading(false)
                // and then get grades
            })
    } 

    const setChangeLevel = (level) => {
        setUserLevel(level)
    }
    const setChangeSection = (section) => {
        setSectionFullName(section)
    }
    return (
        <Container>
            {isLoading ? <div>Loading...</div> : 
                studyPlan === undefined ? SelectSection(userLevel, sectionFullName, setChangeLevel, setChangeSection, changeSection) :
                <StudyPlanTable sectionInfo={sectionInfo} studyPlan = {studyPlan as IStudyPlan} ></StudyPlanTable>}
            
        </Container>
    )
}

function SelectSection(userLevel, sectionFullName, setChangeLevel, setChangeSection, changeSection){
    return ( 
        <div className='mt-4 text-lg border border-grey-100 p-10'>
            <h1 className='text-2xl' > Welcome! 😛 </h1>
            <h1 > First time here? Please choose your degree and section </h1>
            <p className='text-gray-400 text-sm'> Dashboard is intended to help you manage your required and optional courses, track your progress, and even a GPA calculator. <br/> However, we are still in the beta version, 
                so please let us know if you have any suggestions or feedbacks! 
            </p>

            <br></br>
            <div className ="text-left">
                <label htmlFor="userLevel">My degree: </label>
                <select id='userLevel' className="border border-black ml-3" value={userLevel} onChange={(e) => {setChangeLevel(e.target.value)}}>UserLevel
                    <option value=""> None </option>
                    <option value="PR"> Propedeutics(first year bachelor's) </option>
                    <option value="BA">Bachelor's </option>
                    <option value="MA">Master's </option>
                    
                </select>
            </div>
            <br></br>
            <div className="text-left">
                <label htmlFor="userLevel">My Section: </label>
                <select id='userLevel' className='border border-black ml-3 w-25' value={sectionFullName} onChange={(e) => { setChangeSection(e.target.value) }} >User Section
                    <option value=""> None </option>
                    { userLevel === "BA" ? BASections.map((v, i) => {
                        return (
                            <option value={v} key={i}>{v}</option>
                        )
                    }): userLevel === "MA" ? MASections.map((v, i) => {
                        return (
                            <option value={v} key={i}>{v}</option>
                        )
                    }) : PropedeuticsSections.map((v, i) => {
                        return (
                            <option value={v} key={i}>{v}</option>
                        )
                    })}
                </select>
            </div>
            <br></br>
            <button onClick={changeSection} className="bg-blue-200 rounded-md p-2">Save</button>
        </div>)
}
