import React, { useContext } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import AuthContext from "./context/AuthContext";

function Navigation() {
  const role = useContext(AuthContext).role;
  return (
    <Navbar bg="white" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/logo.png"
            height="30"
            width="30"
            id="coursenametitle"
            className="d-inline-block align-top"
            alt=""
          />
          <span className="font-extrabold text-2xl">CourseFinder </span>
        </Navbar.Brand>
        <p className="text-xs">for EPFL students</p>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="me-auto justify-content-end"
        >
          <Nav>
            <Nav.Link
              href="/"
              className="p-2 mt-1 text-black font-bold hover:bg-gray-300 hover:shadow-lg rounded-md"
            >
              Browse classes
            </Nav.Link>

            <Nav.Link
              href="/buck"
              className="p-2 mt-1 text-black font-bold hover:bg-gray-300 hover:shadow-lg rounded-md"
            >
            <span className="blink-span">Bucketlist</span>
            </Nav.Link>
            <Nav.Link
              href="/rate"
              className="p-2 mt-1 text-yellow-800 font-bold bg-yellow-200 hover:shadow-lg hover:bg-yellow-500 rounded-md"
            >
              Write a review!
            </Nav.Link>
            
            <Nav.Link
              href="/about"
              className=" p-2 mt-1 text-black font-bold hover:bg-gray-300 hover:shadow-lg rounded-md"
            >
              About{" "}
            </Nav.Link>
            {/* {
              <Nav.Link
                href="/rate2"
                className="p-2 mt-1 bg-sky-500 hover:bg-sky-800 text-white rounded-md"
              >
                Ratev2
              </Nav.Link>
            } */}
            {/* <Nav.Link
              href="/mystudyplan"
              className="p-2 mt-1 hover:text-blue-700 hover:bg-blue-200 rounded-md"
            >
              Dashboard (beta)
            </Nav.Link> */}
            {/* <Nav.Link
              href="/4k"
              className="p-2 mt-1 hover:text-blue-700 hover:bg-blue-200 rounded-md"
            >
              4K weeks
            </Nav.Link> */}
            {/* <Nav.Link
              href="/settle"
              className="p-2 mt-1 bg-sky-500 hover:bg-sky-800 text-white rounded-md"
            >
              Let's settle this
            </Nav.Link> */}
            {/* <Nav.Link
              href="/logout"
              className="p-2 mt-1 hover:text-blue-700 hover:bg-blue-200 rounded-md"
            >
              Log out
            </Nav.Link> */}
            {/* {role === "admin" ? (
              <Nav.Link
                href="/adminrate"
                className="p-2 mt-1 bg-red-400 hover:bg-red-500 rounded-md"
              >
                Admin rate
              </Nav.Link>
            ) : null} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
