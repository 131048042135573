import React from 'react';

function NotFoundPage (){
    return(
        <div className = 'container items-center mt-5 text-lg'>
            <h1>
                Sorry you've reached a wrong page 
                <br></br>
                If need help please go to the about page, and contact the site maintainer. 
            </h1>
        </div>
    )
}

export default NotFoundPage;