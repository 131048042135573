import {Routes, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import Navigation from './Navigation';
import RatingForm from './pages/RatingPage';
import About from './pages/About';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import Logout from './pages/Logout';
import NotFoundPage from './pages/NotFoundPage'
import MyStudyplan from './pages/MyStudyplan'
import RatingFormAdmin from './pages/AdminRatingPage';
import AdminBucketItem from './pages/AdminBucketItem';
import './index.css'
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAdmin from './components/ProtectedAdmin';
import MyList from './pages/MyList';
import LabelTool from './pages/Label';
import { DndProvider } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Banner from './components/Banner';
import FourKWeeks from './pages/4k';
import RatingForm2 from './pages/RatingPage2';
import { SettlePage } from './pages/Settle';
import { BucketlistPage } from './pages/BucketList';
import NewNavigationBar from './components/NewNavigationBar';
function App() {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
      <BrowserRouter >
          <Banner />
          <Navigation></Navigation>
          <Routes>
            {/* //public routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/about" element={<About />} /> 

          {/* protected routesNeed a valid token to access the content of the route */}
          <Route path="/" element={ <Home />} /> 
          <Route path="/home" element={<Home /> } /> 
          <Route path="/rate" element={<RatingForm />} />
          <Route path="/rate2" element={<RatingForm2 />} />
          <Route path="/settle" element={<SettlePage />} />
          <Route path="/buck" element={<BucketlistPage />} />
          {/* <Route path="/course/:courseCode" element={<CoursePage />} /> */}
          <Route path="/mystudyplan" element={<ProtectedRoute><MyStudyplan /></ProtectedRoute>} />
          <Route path="/logout" element = {<ProtectedRoute> <Logout/> </ProtectedRoute>} />
          {/* <Route path="/adminrate" element={<ProtectedAdmin> <RatingFormAdmin /> </ProtectedAdmin>} /> */}
          <Route path="/4k" element = {<FourKWeeks></FourKWeeks>} />
          <Route path='adminbucket' element = {<AdminBucketItem></AdminBucketItem>}> </Route>

          {/* IN DEVELOPMENT DON'T DEPLOY */} 
            <Route path="/mylist" element={<MyList /> } />
            <Route path="/label" element={<LabelTool />} />

          {/* everything else */}
          { /**/ }
          <Route path="*" element = {<NotFoundPage/>} />
          </Routes>
      </BrowserRouter>
      </DndProvider>
    </div>
  );
  }

 
export default App;
