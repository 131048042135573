import React from "react";
import "./Banner.css";

export default function Banner(){
    return (
        <div id="pageBanner" className="banner" onClick={()=>{
            document.getElementById("pageBanner").style.display = "none" }
            }>
            <div className="flex justify-center">
                <span className="fw-bold"> Happy new semester </span>
                <span className="pl-1">| Review for the community! </span>
            </div>
  
        </div>
    )
}