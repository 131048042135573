import React, { Dispatch, SetStateAction, ReactElement } from "react";
import { Form } from "react-bootstrap";
import { sectionCode } from "../components/UsefulConstants";
import { Tooltip } from "react-tooltip";
import { motion } from "framer-motion";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

interface SortOrder {
  column: string;
  ascending: boolean;
}

/// SVG icon variants
const pathVariants = {
  up: {
    d: "M0,4 L4,0 L8,4",
    transition: {
      duration: 0.3,
    },
  },
  down: {
    d: "M0,0 L4,4 L8,0",
    transition: {
      duration: 0.3,
    },
  },
  flat: {
    d: "M0,2 L4,2 L8,2",
    transition: {
      duration: 0.3,
    },
  },
};

function SortOrderButton(props: {
  column: string;
  setSortOrder: Dispatch<SetStateAction<SortOrder>>;
  sortOrder: SortOrder;
}): ReactElement {
  const { column, setSortOrder, sortOrder } = props;

  // Updates the sort order when user clicks on button
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const column = event.currentTarget.dataset.column;
    setSortOrder((prev: SortOrder) => {
      if (prev.column === column) {
        return { column: prev.column, ascending: !prev.ascending };
      }
      return { column: column, ascending: true };
    });
  }

  // Get new icon
  let variant: string;
  if (sortOrder.column !== column) {
    variant = "flat";
  } else if (sortOrder.ascending) {
    variant = "up";
  } else {
    variant = "down";
  }

  return (
    <button
      onClick={handleClick}
      data-column={column}
      className="mt-auto ml-auto mr-1 mb-2"
    >
      <svg width="8" height="4" className="overflow-visible">
        <motion.path
          variants={pathVariants}
          initial="up"
          animate={variant}
          fill="none"
          stroke="currentColor"
          stroke-width="1.6"
        ></motion.path>
      </svg>
    </button>
  );
}

function FilterBar(props: {
  setSection: Dispatch<SetStateAction<string>>;
  setTerm: Dispatch<SetStateAction<string>>;
  setExam: Dispatch<SetStateAction<string>>;
  setCredit: Dispatch<SetStateAction<string>>;
  setSearchTerms: Dispatch<SetStateAction<string>>;
  searchTerms: string;
  setSortOrder: Dispatch<SetStateAction<SortOrder>>;
  sortOrder: SortOrder;
}): ReactElement {
  const {
    setSection,
    setTerm,
    setExam,
    setCredit,
    setSearchTerms,
    searchTerms,
    setSortOrder,
    sortOrder,
  } = props;
  const sections = [...sectionCode];
  const credits = Array.from(Array(12).keys());
  credits.shift();

  return (
    <div className="grid grid-cols-12 pl-4 pr-4 font-medium sticky-top">
      <div className="d-flex flex-column col-span-3 md:col-span-1 bg-gray-100 hover:bg-gray-300 border text-center rounded ">
        <label htmlFor="section" id="sectionselector">
          Section
        </label>
        <select
          id="section"
          onChange={(v) => {
            setSection(v.target.value);
          }}
          className="bg-gray-100"
        >
          <option value="">All</option>
          {sections.map((v, i) => {
            return (
              <option value={v} key={i}>
                {" "}
                {v}
              </option>
            );
          })}
        </select>
        <SortOrderButton
          column="courseCode"
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 bg-gray-100 hover:bg-gray-300 border text-center rounded">
        <label htmlFor="term" id="sectionselector">
          Term
        </label>
        <select
          id="term"
          onChange={(v) => {
            setTerm(v.target.value);
          }}
          className="bg-gray-100"
        >
          <option value="">Any</option>
          <option value="Summer session"> Summer </option>
          <option value="Winter session"> Winter </option>
        </select>
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 bg-gray-100 hover:bg-gray-300 border text-center rounded">
        <label htmlFor="exam" id="sectionselector">
          Exams
        </label>
        <select
          id="exam"
          onChange={(v) => {
            setExam(v.target.value);
          }}
          className="bg-gray-100"
        >
          <option value="">Any</option>
          <option value="Oral"> Oral </option>
          <option value="Written"> Written </option>
          <option value="During the semester"> During the semester </option>
          <option value="Term paper"> Term paper </option>
        </select>
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 bg-gray-100 hover:bg-gray-300 border text-center rounded">
        <label htmlFor="credit" id="sectionselector">
          Credit
        </label>
        <select
          id="credit"
          onChange={(v) => {
            setCredit(v.target.value);
          }}
          className="bg-gray-100"
        >
          <option value={0}>Any</option>
          {credits.map((v, i) => {
            return (
              <option value={v} key={i}>
                {v}
              </option>
            );
          })}
        </select>
      </div>

      <div className="d-flex flex-column col-span-12 md:col-span-4 bg-gray-100 hover:bg-gray-300 border text-center rounded">
        <Form.Group>
          <Form.Label>Search Course</Form.Label>
          <Form.Control
            type="coursecode"
            onChange={(event) => setSearchTerms(event.target.value)}
            value={searchTerms}
            placeholder="by name, prof, course code, e.g. MGT-481 "
          />
        </Form.Group>
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 font-sm bg-gray-300 hover:bg-gray-300 border text-center rounded">
        <a
          data-tooltip-id="overall"
          data-tooltip-content="avg. of the overall review of this course"
        >
          Overall
        </a>
        <Tooltip id="overall" />
        <a
          className="text-xs"
          data-tooltip-id="#"
          data-tooltip-content="total number of reviews for this course"
        >
          (#)
        </a>
        <Tooltip id="#" />
        <SortOrderButton
          column="avgRating"
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 font-sm bg-gray-300 hover:bg-gray-300 border text-center rounded">
        <a
          data-tooltip-id="profRating"
          data-tooltip-content="avg. of the professor's clarity, organization, accessibility "
        >
          Prof Rating
        </a>
        <Tooltip id="profRating" />
        <SortOrderButton
          column="avgProfRating"
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 font-sm bg-gray-300 hover:bg-gray-300 border text-center rounded">
        <a
          data-tooltip-id="difficulty"
          data-tooltip-content="avg. of the overall difficulty of the course"
        >
          Difficulty
        </a>
        <Tooltip id="difficulty" />
        <SortOrderButton
          column="avgDifficulty"
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>

      <div className="d-flex flex-column col-span-3 md:col-span-1 font-sm bg-gray-300 hover:bg-gray-300 border text-center rounded">
        <a
          data-tooltip-id="difficulty"
          data-tooltip-content="how much work when compared to the number of credits"
        >
          Workload
        </a>
        <Tooltip id="workload" />
        <a
          className="text-xs"
          data-tooltip-id="credits"
          data-tooltip-content="number of credits"
        >
          (credits)
        </a>
        <Tooltip id="credits" />
        <SortOrderButton
          column="avgWorkload"
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </div>
    </div>
  );
}

export default FilterBar;
