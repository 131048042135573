import React ,{ useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import axiosConfig from "../axiosConfig";
import { ICourse, ISectionInfo, IStudyPlan } from "../types";
// TODO: not sure if objectID should be Mongoose.Types.ObjectId or string



function StudyPlanTable (props: {sectionInfo: ISectionInfo, studyPlan: IStudyPlan }) {
    const {sectionInfo, studyPlan} = props
    const [totalCredit, setTotalCredit] = useState(0)
    const [avgGPA, setAvgGPA] = useState(0)
    const [creditTracker, setCreditTracker] = useState(studyPlan.creditTracker)
    const [GPATracker, setGPATracker] = useState(studyPlan.GPATracker)

   
    const coursesByCategory = sectionInfo.categories
    const  keys = Object.keys(coursesByCategory)
    

    // helper functions beneath 

    const changeTotalCredit = (checked: boolean, credit:number, objectID:string ) => {
        if (checked) {
            // previously wasn't chosen  
            setCreditTracker({ ...creditTracker, [objectID]: credit})
        } else {
            // delete this credit information 
            setCreditTracker({ ...creditTracker, [objectID]: 0  })
        } 
    }
    const changeGPA = (grade:number, objectID:string) => {
        setGPATracker({ ...GPATracker, [objectID]: grade})
    }

    const saveInfo = () => {
        axiosConfig.post("/studyplan", {"creditTracker":creditTracker, "GPATracker": GPATracker})
    }

    // if the user has previously saved Studyplan, this will pre-fill the information. 
    const defaultCredit = (objectID:string) => {
        if (creditTracker[objectID] > 0) {
            return true
        }
        return false 
    }
    const defaultGrade = (objectID: string) => {
        return GPATracker[objectID]
    }

    // whenever Trackers change, update GPA and total credit 
    useEffect(() => {
        let sum = 0
        let totalGPA = 0
        for (let key in creditTracker) {
            sum += creditTracker[key]
            totalGPA += Number(GPATracker[key]) * creditTracker[key]
        }
        setTotalCredit(sum)
        setAvgGPA(Math.round((totalGPA/sum) * 100) /100)

    }, [creditTracker, GPATracker])
    
    return (    
        <div className="mt-3">
            <div className='grid grid-cols-12 h-8 '>
                <p className="hidden md:block col-span-5">Section:{sectionInfo.userInfo.sectionFullName}, <br></br> Level: {sectionInfo.userInfo.userLevel}</p>
                <p className='col-span-5 md:col-span-1 font-semibold '>Total Credit: <br></br> {totalCredit}</p>
                <p className='col-span-5 md:col-span-1 font-semibold '>Your GPA: <br></br>{avgGPA}</p>
                <div className="hidden md:block col-span-4 pl-4 text-xs text-gray-400"> We don't save your information by default. <br></br> Click "save" for next time use. </div>
                <button className='col-span-2 md:col-span-1 bg-blue-400 m-1 hover:bg-blue-600 rounded-md text-white font-semibold' onClick={()=> saveInfo()}>Save</button>
            </div>

            <div>
             {keys.map((objectKey, index) => {
                    return (
                        <div key={index}>
                           {StudyPlanHeader(objectKey)}
                            {coursesByCategory[objectKey].map((course, id) => {
                                return StudyPlanRow(course, id, changeTotalCredit, changeGPA, defaultCredit, defaultGrade)
                            })}
                        </div>
                    )
             })}
            </div>

        </div>
    )
}

function StudyPlanRow(course: ICourse, id: number, 
    changeTotalCredit: (checked: boolean, credit: number, objectID: string) => void ,
    changeGPA: (grade: number, objectID: string) => void, defaultCredit: (objectID: string)=>boolean, defaultGrade: (objectID: string)=> number){
    const location = useLocation()

    return (
        course && <div key = {id} className="grid grid-cols-12 border-white bg-sky-100 border-2 rounded-md">
            <div className="col-span-2 md:col-span-1 border-1">{course.courseCode }</div>
            <div className="hidden md:block col-span-6 border-1">{course.courseName}</div>
           {( () => {
                return course.term === "Summer session" ? 
                    <div className="col-span-2 md:col-span-1 border-1 text-xl">🍃</div> : 
                    < div className= "col-span-2 md:col-span-1 border-1 text-xl" >🍁</div>
            }) ()}

            <div className="col-span-2 md:col-span-1 border-1">{course.credit}</div>
            
            <div className="col-span-2 md:col-span-1 ">
                <input id="havetaken" defaultChecked={defaultCredit(course._id)} type="checkbox" className="border border-black-300 rounded-md h-6 w-6"
                onChange={(e) => changeTotalCredit(e.target.checked, course.credit, course._id)}></input>
               <label htmlFor="havetaken"></label>
            </div> 
            <form className="col-span-2 md:col-span-1">
                <input id="grade" type="number" step={0.25} value={defaultGrade(course._id)} className=" w-8 md:text-md w-16 border border-black rounded-md "
                onChange={(e) => changeGPA(e.target.valueAsNumber, course._id)}></input>
            </form>
           
            <Link
                className="col-span-2 md:col-span-1 bg-blue-200 hover:bg-blue-400  text-center rounded-md m-1 text-sm"
                to={`/rate?courseCode=${course.courseCode}`} state={{from: location}} 
                > review
            </Link>
        </div>
    )
}

function StudyPlanHeader(optionName: string){
    return (
        <div className="grid grid-cols-12 font-xs md:font-semibold border-2 rounded-md  bg-gray-200 mt-3">
            <div className="col-span-1 md:col-span-1 border-1">{optionName}</div>
            <div className="hidden md:block col-span-6 border-1">course</div>
            <div className="col-span-2 md:col-span-1 border-1">term</div>
            <div className="col-span-2 md:col-span-1 border-1">credit</div>
            <div className="col-span-2 md:col-span-1">taken</div>
            <div className="col-span-2  md:col-span-1">grade</div>
        </div>
    )
}


export default StudyPlanTable
