import { IBucketItem } from "../types";
export default function BucketItemCard(props: { card: IBucketItem, onClickFunc: (props) => void }) {
    const { card, onClickFunc } = props;
    return (
        <div id={`card-${card._id}`} className="col-span-1 rounded shadow-md hover:border-2 hover:border-gray-400 hover:shadow-lg">
            <div className="px-2 md:px-4 py-1 md:py-2">
                <div className="font-bold text-base md:text-xl mb">
                    <input id={`checkbox-${card._id}`} type='checkbox' className="mr-2 w-4 h-4" onClick={(e) => {
                        let current = document.getElementById(`checkbox-${card._id}`) as HTMLInputElement
                        if (!current.checked) {
                            onClickFunc({
                                objectID: card._id,
                                action: 'decrease',
                                itemScore: card.itemScore
                            })
                            document.getElementById(`card-${card._id}`).className = 'grid-span-1 rounded shadow-sm hover:border-2 hover:border-gray-400 hover:shadow-lg'

                        } else {
                            onClickFunc({
                                objectID: card._id,
                                action: 'increase', 
                                itemScore: card.itemScore
                            })
                            document.getElementById(`card-${card._id}`).className = 'border-2 border-gray-800 bg-gray-100 grid-span-1 rounded shadow-sm'
                        }
                    }} />
                    {card.itemName}
                </div>

                <div className=" text-gray-700 text-xs md:text-sm">
                    {card.itemDescription}
                </div>

                <div>
                    <span className="bg-gray-100 text-gray-800 text-sm font-medium mt-8 mr-2 px-2.5 py-0.5 rounded">
                        🙋{card.checkedCount}
                    </span>
                    {
                        card.itemScore > 0 ?
                            <span className="bg-gray-100 text-gray-800 text-sm font-medium mt-8 mr-2 px-2.5 py-0.5 rounded">
                                ⭐ +{card.itemScore}
                            </span> :
                            <span className="bg-gray-100 text-gray-800 text-sm font-medium mt-8 mr-2 px-2.5 py-0.5 rounded">
                                ⭐ {card.itemScore}
                            </span>
                    }
                </div>
            </div>
        </div>
    )
}
