
import React, { useState, useContext, useEffect,  } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const ProtectedRoute =  (props:{ children: React.ReactNode}) => {
    const { children } = props
   const {loggedIn, getLoggedIn} = useContext(AuthContext)
   const [loading, setLoading ] = useState(true)
   const location = useLocation()

   useEffect (()=>{
    const CheckLoggedin = async () => {
        try {
            await getLoggedIn()
        } catch (err) {
            console.log("protected route access error")
        } finally{
            setLoading(false)
        }
    }
       CheckLoggedin()
   }, [])

   if (loading) {
    return <div>Loading.....</div>
   }
 
   return ( 
    <div >
        {
            loggedIn ? children : <Navigate to={'/login'} state = {{from: location}} replace />
        }


    </div>
   )
}

export default ProtectedRoute 