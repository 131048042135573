import React, { useEffect, useState} from "react";
import {useSearchParams, useLocation, Navigate, useNavigate} from "react-router-dom";
import {Container, Form} from "react-bootstrap";
import axiosConfig from "../axiosConfig";
import './RatingPageStyle.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ILocationProps } from "../types";


function RatingForm() {
  const [searchText, setSearchText] = useState("");
  const [courseId, setCourseId] = useState("");
  const [allCoursesInfo, setAllCoursesInfo] = useState([{
    courseId: '',
    courseDescriptionString: '',
    courseCode: '',
    alreadySubmitted: undefined, 
  }]) // array of course info objects, used for the search bar
  const [courseRating, setCourseRating] = useState(undefined);
  const [workload, setWorkload] = useState(undefined);
  const [profRating, setProfRating] = useState(undefined);
  const [difficulty, setDifficulty] = useState(undefined);
  const [comment, setComment] = useState("");
  const [studyTip, setStudyTip] = useState("");
  const [searchParams] = useSearchParams();
  const [pageStatus, setPageStatus] = useState(0);

  const location = useLocation() as unknown as ILocationProps
  let navigate = useNavigate(); 
  const from = location.state?.from?.pathname || "/rate"; 

  // Load once at the beginning, fills up allCoursesInfo with searchable information, get a list of reviewed courses
  useEffect(function findListInfo() { 
    axiosConfig.get('/submitRating')
      .then((response)=>{
        const allCoursesInfo = response.data.map((course) => {
          // concatenate all key information into a string to help with search, in the future could be better stored instead of made here
          let courseInfo = {
            courseDescriptionString: `${course.courseCode} ${course.courseName} by ${course.profNames.join(" ")}`,
            courseCode: course.courseCode,
            courseId: course._id,
            alreadySubmitted: course.alreadySubmitted,
          }
          return courseInfo
        })
        setAllCoursesInfo(allCoursesInfo)
      })
  }, []);

  /* Fill course name from course code in query string */
  useEffect(function parseCourseCodeSearch() {
    const courseCodeSearch = searchParams.get('courseCode')
    allCoursesInfo.forEach(course => {
      if (course.courseCode === courseCodeSearch) {
        setSearchText(course.courseDescriptionString)
        setCourseId(course.courseId)
        if (course.alreadySubmitted){
          setPageStatus(1)
        }
      }
    })
  }, [searchParams, allCoursesInfo]);

  /* Submit 1 rating for 1 course */
  const submitForm = async (e) => {
    /* TODO: fix the request failing in Firefox
     - https://github.com/axios/axios/issues/2155 */
    e.preventDefault();
      if (searchText === "" || courseId === "" || courseRating === 0 || difficulty === 0 || workload === 0|| profRating === 0|| comment === "" || studyTip === "")
      { 
       alert("please fill all the fields")
       return 
      }
      if (countWords(comment) < 10 || countWords(studyTip) < 10) {
        alert("Reviews must be at least 10 words long. Please be more detailed. We really appreciate it! 🙏")
        return 
      }
      axiosConfig.post('/submitRating', {
        courseCode: searchText,
        courseId: courseId,
        courseRating: courseRating,
        difficulty: difficulty,
        workload: workload,
        profRating: profRating,
        comment: comment,
        studyTip: studyTip,
      })
      .then(async(res) => { 
        setPageStatus(2)
        await new Promise(r => setTimeout(r, 3000));
        if (from === "/rate") {
          window.location.reload();
        } else {
          navigate(from, { replace: true }) 
        }
        
      })
      .catch((error) => {
      alert(`Received error: ${error.response.data.error} ` )
    })
  }

  /* ====== BIG RETURN ======= */
  const searchTextProcessed = searchText.toLowerCase().trim()
  return (
    <Container>
      {pageStatus === 2 ? 
        <div className="border bg-green-200 rounded-md ">
          <h1 className="text-2xl "> Successful! Thank you !! 🎉 <br></br> Your review will help other students. 🙏 </h1> 
          <p className="text-sm"> redirecting... </p>
      </div>: pageStatus === 1 ?
          <div className="border bg-red-200 rounded-md ">
          <h1 className="text-2xl "> You have already reviewed this class. You cannot submit twice. </h1>
        </div> : 
      <Form>
          <div className="mb-3 searchbar font-bold ">
          <Form.Group > 
          <Form.Label>Search Course</Form.Label>
          <Form.Control type="coursecode" onChange={event => {setSearchText(event.target.value); setPageStatus(0)}} value={searchText} placeholder="by name, prof, course code, e.g. MGT-481 "/>
          </Form.Group>
      </div>
      {/* Search results displayed below the text field */}
      <div>
        {allCoursesInfo.map((course, index ) => {
          if (searchText !== "" && 
              searchText !== course.courseDescriptionString &&
              course.courseDescriptionString.toLowerCase().includes(searchTextProcessed)) {
            return (
              <div key = {index} onClick={() => {
                setCourseId(course.courseId)
                setSearchText(course.courseDescriptionString)
                setPageStatus(0)
                if (course.alreadySubmitted){
                  setPageStatus(1)
                }
              }}>{course.courseDescriptionString} {course.alreadySubmitted} </div>)
            }
        })}
      </div>

        <label className="font-bold">How is the Course?</label>
      <br></br>
      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" className="btn-check" name="courseradio" id="btnradio1" autoComplete="off" onClick={(e) => {setCourseRating(1)}} />
        <label className="btn btn-outline-secondary" htmlFor="btnradio1">Terrible</label>

        <input type="radio" className="btn-check" name="courseradio" id="btnradio2" autoComplete="off" onClick={(e) => { setCourseRating(2)}}/>
        <label className="btn btn-outline-secondary" htmlFor="btnradio2">Bad</label>

        <input type="radio" className="btn-check" name="courseradio" id="btnradio3" autoComplete="off" onClick={(e) => setCourseRating(3)}/>
        <label className="btn btn-outline-secondary" htmlFor="btnradio3">Okay</label>

        <input type="radio" className="btn-check" name="courseradio" id="btnradio4" autoComplete="off" onClick={(e) => setCourseRating(4)} />
        <label className="btn btn-outline-secondary" htmlFor="btnradio4">Good</label>

        <input type="radio" className="btn-check" name="courseradio" id="btnradio5" autoComplete="off" onClick={(e) => setCourseRating(5)} />
        <label className="btn btn-outline-secondary" htmlFor="btnradio5">Excellent</label>
      </div>

      <br></br>
      <label className="font-bold" > How is the professor? (clairty, organization, accessibility) </label>
      <br></br>

      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" className="btn-check" name="profRadio" id="profradio1" autoComplete="off" onClick={(e) => {setProfRating(1)}} />
        <label className="btn btn-outline-secondary" htmlFor="profradio1">Terrible</label>

        <input type="radio" className="btn-check" name="profRadio" id="profradio2" autoComplete="off" onClick={(e) => { setProfRating(2)}}/>
        <label className="btn btn-outline-secondary" htmlFor="profradio2">Bad</label>

        <input type="radio" className="btn-check" name="profRadio" id="profradio3" autoComplete="off" onClick={(e) => { setProfRating(3)}} />
        <label className="btn btn-outline-secondary" htmlFor="profradio3">Okay</label>

        <input type="radio" className="btn-check" name="profRadio" id="profradio4" autoComplete="off" onClick={(e) => setProfRating(4)} />
        <label className="btn btn-outline-secondary" htmlFor="profradio4">Good</label>

        <input type="radio" className="btn-check" name="profRadio" id="profradio5" autoComplete="off" onClick={(e) => setProfRating(5)} />
        <label className="btn btn-outline-secondary" htmlFor="profradio5">Excellent</label>
      </div>

      <br></br>
      <label className="font-bold"> How is the workload? (relative to the number of credits it's worth) </label>
      <br></br>

      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" className="btn-check" name="workRadio" id="workradio5" autoComplete="off"  onClick={(e) => setWorkload(1)} />
          <label className="btn btn-outline-secondary" htmlFor="workradio5">Very little</label>

          <input type="radio" className="btn-check" name="workRadio" id="workradio4" autoComplete="off"  onClick={(e) => setWorkload(2)} />
          <label className="btn btn-outline-secondary" htmlFor="workradio4">Not much</label>

          <input type="radio" className="btn-check" name="workRadio" id="workradio3" autoComplete="off"  onClick={(e) => { setWorkload(3) }} />
          <label className="btn btn-outline-secondary" htmlFor="workradio3">Appropriate</label>


          <input type="radio" className="btn-check" name="workRadio" id="workradio2" autoComplete="off"  onClick={(e) => { setWorkload(4) }} />
          <label className="btn btn-outline-secondary" htmlFor="workradio2">A lot</label>

        <input type="radio" className="btn-check" name="workRadio" id="workradio1" autoComplete="off"  onClick={(e) => { setWorkload(5) }} />
        <label className="btn btn-outline-secondary" htmlFor="workradio1">Too much </label>

        
      </div>

      <br></br>
      <label className="font-bold"> How difficult is the class?  </label>
      <br></br>

      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
       
        <input type="radio" className="btn-check" name="diffiRadio" id="diffiRadio5" autoComplete="off" onClick={(e) => setDifficulty(1)} />
        <label className="btn btn-outline-secondary" htmlFor="diffiRadio5">Very easy</label>

        <input type="radio" className="btn-check" name="diffiRadio" id="diffiRadio4" autoComplete="off" onClick={(e) => setDifficulty(2)} />
        <label className="btn btn-outline-secondary" htmlFor="diffiRadio4">Not hard</label>

        <input type="radio" className="btn-check" name="diffiRadio" id="diffiRadio3" autoComplete="off"  onClick={(e) => { setDifficulty(3) }} />
        <label className="btn btn-outline-secondary" htmlFor="diffiRadio3">Average</label>


        <input type="radio" className="btn-check" name="diffiRadio" id="diffiRadio2" autoComplete="off"  onClick={(e) => { setDifficulty(4) }} />
        <label className="btn btn-outline-secondary" htmlFor="diffiRadio2">Hard</label>


        <input type="radio" className="btn-check" name="diffiRadio" id="diffiRadio1" autoComplete="off"  onClick={(e) => { setDifficulty(5) }} />
        <label className="btn btn-outline-secondary" htmlFor="diffiRadio1">Very hard</label>

      </div>

      <br></br><br></br>
      <div className="mb-3 font-bold">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">Would you recommend this course to others?  </label>
        <textarea className="form-control" id="commentTextArea" rows={3} onChange={(event) => { setComment(event.target.value) }} 
        placeholder="e.g. topics that you liked, lecture quality" required></textarea>
          <StrengthBar content={comment} />
      </div>

      <div className="mb-3 font-bold">
          <label htmlFor="exampleFormControlTextarea1" className="form-label"> Any study tips? </label>
        <textarea className="form-control" id="studytipTextArea" rows={3} onChange={(event) => { setStudyTip(event.target.value) }} 
        placeholder="e.g. exercises helpful? prerequsites? exams? youtube channels? "  required></textarea>
          <StrengthBar content={studyTip} /> 
      </div>
      
      <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={(e) => {
            submitForm(e); 
            this.disabled = true; 

            }}>Submit</button>
      </div>
      </Form>
      }
    </Container>
         
  )
}

export default RatingForm; 
const StrengthBar = ( {content} ) =>{
  return (<div className="mt-1">length: {
    countWords(content) < 8 ? 
      Array.from(new Array(Math.floor(countWords(content) /3) + 1 ), (_, i) => {
        return <span className="mr-px">🟥</span> 
      }) : countWords(content) < 20 ? Array.from(new Array(Math.floor(countWords(content) / 3) + 1), (_, i) => {
        return <span className="mr-px">🟨</span>
      }) : Array.from(new Array(10), (_, i) => {
        return <span className="mr-px">🟩</span>
      }) 

  }</div>)

}

const countWords = (str) =>{
  return str.trim().split(/\s+/).length;
}