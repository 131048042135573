import React, {useEffect, useState} from 'react'
import {jsPDF} from 'jspdf'
import PDFObject from 'pdfobject'
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'react-bootstrap';

/**
 * This is a page to calculate how many days of your life you have left, 
 * and generate a poster of dots reprenseting the weeks left. 
 * - input birthday 
 * - display dots 
 * - generate and download the poster 
 **/

/** TODO:
 * 4. add a download button
 * 埋一个点，在google analytics上，看有多少人能下载
 * **/

function FourKWeeks(){
    const [choice, setChoice] = useState(2); // 1 for weeks in life, 2 for habit tracker
    return(
        <div className='h-full'>
            <div className='grid grid-cols-4'>
                <div className='col-span-1 '></div>
                <div className='col-span-1 border-4 m-4 text-center rounded-md bg-sky-500 hover:bg-sky-700 border-transparent font-bold text-white' onClick={()=>{setChoice(1)}}> Weeks in Life </div>
                <div className='col-span-1 border-4 m-4 text-center rounded-md bg-sky-500 hover:bg-sky-700 border-transparent font-bold text-white' onClick={()=>{setChoice(2)}}> Habit Tracker </div>
                <div className='col-span-1'> </div>
            </div>
            {
                choice === 1 ? <WeeksInLife></WeeksInLife> : <HabitTracker></HabitTracker>
            }
        </div>
    )
}

function HabitTracker(){
    useEffect(() => {
        PDFObject.embed(generateHabitPdf1Month().output('datauristring'), '#pdf');
    }, [])

    return (
        <div className='grid grid-cols-12 h-full'>
        
        <div className='col-span-7 font-serif m-6'>
        <p className='text-4xl'>New semester. Fresh start.</p>
        <br></br>
        <p>Want to form a new habit this year? </p>
        <br></br>
                <p>Whether that's hit the gym more often, learn French, or quit smoking, we are here to help.</p>
        <br></br>

        <p>Print out the following 30-day tracker sheet and fill the bubble every time you succeed in your goal.</p>
        <br></br>
        <p> Every progress is worth celebrating, regardless of the size.</p>
        </div>
        
        <div id='pdf' className='col-span-5 p-4'></div>

        </div>
    )
}


function generateHabitPdf1Month(){
    const doc = new jsPDF({ format: 'a4' });
    doc.setFont('courier', 'bold'); 
    doc.setFontSize(30);
    doc.text('My goal is: ', 20, 65);
    doc.setFontSize(15);
    doc.text('Start date: ', 20, 80);
    for (let i = 0; i < 5; i++) { // how many weeks there are 
        // doc.text(`week ${31 - i}`, 25, 30 + i * 8); // text, x, y 
        for (let j = 0; j < 7; j++) { // each rect for one week 
            if (j!== 0 && (j %6 === 0 || j% 5 === 0)){
                doc.setDrawColor("#9EB384")
            } else {
                doc.setDrawColor("#435334")
            }
            doc.rect(70 + 10 * j, 100 + i * 8, 6, 6) // x, y, w, h 
        }
    }
    return doc;
}

function generateHabitPdf3Month() {
    const doc = new jsPDF({ format: 'a4' });
    doc.text('My goal is: ', 50, 50);

    return doc;
}

function YiyuanFrenchGoals() {
    const doc = new jsPDF({ format: 'a4' });
    doc.setFontSize(14);
    doc.text('C2 exam in days', 50, 10);
    for (let i = 0; i < 27; i++) { // how many weeks there are 
        doc.text(`week ${27 - i}`, 25, 30 + i * 8); // text, x, y 
        for (let j = 0; j < 7; j++) { // each rect for one week 
            doc.rect(50 + 10 * j, 25 + i * 8, 6, 6) // x, y, w, h 
        }
    }
    return doc;
}

function WeeksInLife() {
    const [bDay, setBDay] = useState(new Date(2002, 1, 1));
    const [numWeeks, setNumWeeks] = useState(887);
    // need to make this faster, right now it's too slow
    const calculateWeeks = (() =>{
        const today = new Date();
        const diffTime = Math.abs(today.getTime() - bDay.getTime()); // returns in miliseconds
        console.log("difference in weeks is ", diffTime / (86400000 * 7));
        setNumWeeks(Math.floor(diffTime / (86400000 * 7)));
    })

    useEffect(() => {
        PDFObject.embed(generateWeeksPDF(numWeeks).output('datauristring'), '#pdf');
    }, [numWeeks])

    return (
            <div className="grid grid-cols-12 h-full">
            <div className='container col-span-7 p-4 font-serif'>
                <p className='text-4xl'>Live ardently. Live earnestly.</p>
                <br></br>
                <p className="text-l"> How many weeks do you have in life?
                <br></br> The answer is around 4000. 
                <br></br>As an EPFL student, most likely you've lived
                    around 1000 weeks. 
                <br></br>Here is a tool to visualize your life in weeks. </p>
                <br></br>
                <div className='text-l'>
                    Enter your birthday(m-d-y):
                    <DatePicker className='border-2 border-slate-400' selected={bDay} onChange={(date) => setBDay(date)}></DatePicker>
                </div>
                <div className='w-1/4 border-4 m-2 text-center rounded-md bg-slate-400 hover:bg-slate-700 border-transparent font-bold text-white'
                onClick={()=>{calculateWeeks()}}>Calculate</div>
                <div>You've lived {numWeeks} weeks in your life. Assuming you live to 90 yrs old, you have {4000-numWeeks} weeks left. </div>
                <br/>

                <p>On average, the moment you graduate from high school and begin university, you had already used up 93% your in-person time with your parents. 
                    You're enjoying the last 5% left. </p>
                <br></br>
                <p>"When you look at that reality, you realize that despite not being at the end of your life,
                    you may very well be nearing the end of your time with some of the most important people in your life."</p>
                <br></br><br></br>
                <p>This tool is inspired by <a href='https://waitbutwhy.com/2015/12/the-tail-end.html'>this blog post</a> by Tim Urban.</p>


                </div>
                <div id='pdf' className='col-span-5 p-4'></div>
                
            </div>
    )
} 

function generateWeeksPDF(numWeeks: number) {
    const doc = new jsPDF({format:'a2'});
    // doc.line(210, 0, 210, 594, 'S');
    // title 
    doc.setFontSize(60);
    doc.setFont('Courier', 'Bold');
    doc.text('My Life In Weeks', 210, 40, { align: 'center' });
    // squares 
    const initialX = 64.5;
    const initialY = 65;
    const restY = 3; 
    const restX = 2.4;
    const spaceY = 5;
    const spaceX = 4.8;
    const squareSize = 3;
    var x = initialX; 
    var y = initialY;
    doc.setDrawColor("#D8D9DA");
    doc.setFillColor("#364F6B")
    for (let j = 0; j < 99; j++ ){ // 9 big rows, 90 years
        if (j %11 === 0) { //every 10 rows a big gap 
            y +=restY;
        } else {
            y += spaceY;
            x = initialX;
            for (let i = 0; i < 65; i++) {
                if (i % 5 === 0) {
                    x += restX;
                } else {
                    x += spaceX; 
                    if (j * 52 + i < numWeeks) {
                        // can potentially use roundedRect, but rendering really slow
                        doc.rect(x, y, squareSize, squareSize, 'F')
                    } else {
                        doc.rect(x, y, squareSize, squareSize, )
                    }
                    
                }
            }
        }
    }
        return doc; 
}


export default FourKWeeks