
import React, { useState, useEffect} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import axiosConfig from '../axiosConfig';


const LabelTool = () => {
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        axiosConfig.get('/label')
        .then((res) => {
            console.log("res is ", res.data)
            setReviews(res.data);
        })
    }, []);

    return (
    <div>
    Label Tool
    {   reviews.map((review, index) => {
        return <div key = {index}>{JSON.stringify(review)}</div>
    })}

    </div>)

}

export default LabelTool;