
import { ICourse } from "../types";
export type CompareCourseFunc = (a: ICourse, b: ICourse) => number;

/**
 * Compares courses using a given comparison function such that courses with
 * undefined average ratings come last.
 */

export function CompareCourse(
    courseA: ICourse,
    courseB: ICourse,
    compFunc: (a: ICourse, b: ICourse) => number
): number {
    const courseAIsNotNA = typeof courseA.avgRating !== "undefined";
    const courseBIsNotNA = typeof courseB.avgRating !== "undefined";
    if (courseAIsNotNA && courseBIsNotNA) {
        return compFunc(courseA, courseB);
    } else if (courseAIsNotNA) {
        // Only courseA is not NA, so it must come before courseB
        return -1;
    } else if (courseBIsNotNA) {
        // Only courseB is not NA, so courseA must come after it
        return 1;
    }
    // Both courses are NA, no swap needed
    return 0;
}