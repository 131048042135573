import { useEffect, useState } from "react"
import axiosConfig from "../axiosConfig"

export default function AdminBucketItem(){
    const [itemName, setItemName] = useState('')
    const [itemDescription, setItemDescription] = useState('')
    const [itemScore, setItemScore] = useState(0)
    const [itemCategory, setItemCategory] = useState('')
    const [allCategories, setAllCategories] = useState([])

    useEffect(()=>{
        axiosConfig.get('/getBucketCategories')
        .then((res)=>{
            console.log(res.data)
            setAllCategories(res.data)
        })
    },[])

    const submitItem = () => {
        axiosConfig.post('/addBucketItem', {
            itemName: itemName,
            itemDescription: itemDescription,
            itemScore: itemScore,
            itemCategory: itemCategory
        }).catch((error) => {
            alert(`Received error: ${error.response.data.error} `)
        })
    }


    return (
        <div className="m-8 font-bold text-lg">
            <div>All categories: &nbsp;&nbsp;
                {
                    allCategories.map((category)=>{
                        return (
                            <div>
                                {category.categoryName} &nbsp; {category._id}
                            </div>
                        )
                    })
                }
            </div>
            <div>What's the item name {itemName} </div>
            <textarea className="border-2" onChange={(e)=>setItemName(e.target.value)}></textarea>

            <div>What's the item description {itemDescription} </div>
            <textarea className="border-2" onChange={(e)=>setItemDescription(e.target.value)}></textarea>

            <div>What's the item score {itemScore} </div>
            <input className="border-2" type="number" onChange={(e)=>setItemScore(parseInt(e.target.value))}></input>
            <div>What's the item category please put objectID {itemCategory} </div>
            <input className="border-2" type="text" onChange={(e)=>setItemCategory(e.target.value)}></input>

            <div>
                <button className="m-2 bg-blue-300 hover:bg-blue-400" onClick={submitItem}> submit </button>
            </div>
            
        </div>
    )
}