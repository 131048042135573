import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation, Navigate, useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import axiosConfig from "../axiosConfig";
import './RatingPageStyle.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ILocationProps } from "../types";
import { unescape } from "querystring";

export default RatingForm2;

type ISearchable = {
    id: string, 
    courseDescriptionString: string, 
}

function RatingForm2(){
    let navigate = useNavigate();
    const location = useLocation() as unknown as ILocationProps;
    const from = location.state?.from?.pathname || '/rate';
    // rating states 
    const [overall, setOverall] = useState(undefined);
    const [profRating, setProfRating] = useState(undefined);
    const [workload, setWorkload ] = useState(undefined);
    const [difficulty, setDifficulty] = useState(undefined);
    
    // search states + submission states 
    const [searchable, setSearchable] = useState([{
        id: "",
        courseDescriptionString: ""
    }]); // searchable = [{ id: objectId, courseDescriptionString: string }]
    const [searchString, setSearchString ]= useState(undefined);
    const [courseObjId, setCourseObjId] = useState(undefined); // courseId = objectId

    // Initialization useEffect
    useEffect(()=>{
        axiosConfig.get("/submitRating2") // this has to be moved to the backend 
        .then((res)=>{
            console.log("searchable is", res.data)
            setSearchable(res.data)
        })

    // submission 

    }, [])
    return (
       <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-1 p-4 md:ml-4">
            <SearchModule searchString={searchString} searchable={searchable} setSearchString={setSearchString} setCourseId={setCourseObjId} ></SearchModule>
            <div id="selectors" >
                <RateSelector name={"overall"} question={"How is the course? "} labels={OVERALLLABELS} setMyVar={setOverall}></RateSelector>
                <RateSelector name={"prof"} question={"How is the professor (clarity, organization, accessibility)?"} labels={PROFLABELS} setMyVar={setProfRating}></RateSelector> 
                <RateSelector name={"workload"} question={"How is the workload (relative to the number of credits)?"} labels={WORKLOADLABELS} setMyVar={setWorkload}></RateSelector>
                <RateSelector name={"difficulty"} question={"How is the difficulty?"} labels={DIFFICULTYLABELS} setMyVar={setDifficulty}></RateSelector>
            </div>
        </div>
        <div >
           <CommentModule></CommentModule>
            <div className="pl-4 m-4">
                <button className="p-3 bg-blue-500 hover:bg-blue-800 rounded-lg text-white">Submit</button>
            </div>
        </div>
       </div>
    )
}

function CommentModule(props:{
}){
    return (
        <div className="col-span-1 pl-4 pr-4 pb-4 md:pt-10 md:ml-4 md:mr-6 md:pt-4">
            <div>
                <label htmlFor="comment" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Would you recommend this course to others?</label>
                <textarea id="comment" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="What do you like/diskalike about this class? What did you learn?"></textarea>
            </div>
            <div>
                <label htmlFor="studytip" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Any study tips?</label>
                <textarea id="studytip" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Did you go to lectures/exercises? What are some prerequsites that are good to have?"></textarea>
            </div>

        </div>

    )

}


function SearchModule(props: { searchString: string, searchable: ISearchable[], setSearchString: Function, setCourseId:Function}){
    const { searchString, searchable,  setSearchString, setCourseId} = props;
    return(
        <div id="search element">
            <p className="block mb-2 mt-2 text-sm md:text-base font-medium text-gray-900 dark:text-white">Search course:</p>
            <input type="text" onChange={(e) => { setSearchString(e.target.value)}}
            className ="bg-gray-50 border w-full md:w-3/5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="by name, prof, course code, e.g. MGT-481 "
            value = {searchString}>
            
            </input>
            { // change according to input string
                searchable.map((course, index)=>{
                    if (searchString !== "" 
                    && searchString !== course.courseDescriptionString 
                    && course.courseDescriptionString.toLowerCase().includes(searchString)){
                        return(
                            <div key = {index} 
                                onClick = {()=>{
                                    setCourseId(course.id)
                                    setSearchString(course.courseDescriptionString)}}
                                className="bg-whtie hover:bg-gray-200 h-1.25">
                                {course.courseDescriptionString}
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

// Button radio group of size 5, pass in group name, labels and setState, can be generalized to accomodate even more type 
function RateSelector(props: { name: string, question: string, labels: String[], setMyVar: Function}){
    const {name, question, labels, setMyVar} = props;
    //grid grid-cols-5 gap-1

    return(
        <div>
            <p className="block mb-2 mt-2 text-sm md:text-base font-medium text-gray-900 dark:text-white">{question}</p>
            <div className="flex flex-nowrap gap-2 rounded-xl bg-gray-200 p-1 ">
                <div className="basis-1/5">
                    <input id={`1${name}`} type="radio" name={name} className="peer hidden" onClick={(e) => { setMyVar(1) }} ></input>
                    <label htmlFor={`1${name}`} className="block cursor-pointer select-none rounded-xl text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">{labels[0]}</label>
                </div>
                <div className="basis-1/5">
                    <input id={`2${name}`} type="radio" name={name} className="peer hidden" onClick={(e) => { setMyVar(2) }}></input>
                    <label htmlFor={`2${name}`} className="block cursor-pointer select-none rounded-xl text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">{labels[1]}</label>
                </div>
                <div className="basis-1/5">
                    <input id={`3${name}`} type="radio" name={name} className="peer hidden" onClick={(e) => { setMyVar(3) }}></input>
                    <label htmlFor={`3${name}`} className=" block cursor-pointer select-none rounded-xl text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">{labels[2]}</label>
                </div>
                <div className="basis-1/5">
                    <input id={`4${name}`} type="radio" name={name} className="peer hidden" onClick={(e) => { setMyVar(4) }}></input>
                    <label htmlFor={`4${name}`} className="block cursor-pointer select-none rounded-xl  text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">{labels[3]}</label>
                </div>
                <div className="basis-1/5">
                    <input id={`5${name}`} type="radio" name={name} className="peer hidden" onClick={(e) => { setMyVar(5) }}></input>
                    <label htmlFor={`5${name}`} className="block cursor-pointer select-none rounded-xl  text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">{labels[4]}</label>
                </div>

            </div>
        </div>
        
    )
}
const OVERALLLABELS = ['Terrible', 'Bad', 'Okay', 'Good', 'Excellent'];// 1 2 3 4 5 
const PROFLABELS = ['Terrible', 'Bad', 'Okay', 'Good', 'Excellent'] // 1 2 3 4 5 
const WORKLOADLABELS = ['Very little', 'Not much', 'Appropriate', 'A lot', 'Too much'] // 1 2 3 4 5 
const DIFFICULTYLABELS = ['Very easy', 'Easy', 'Average', 'Hard', 'Very hard'] // 1 2 3 4 5 

