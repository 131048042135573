import React from 'react'
function About () {
    return (
        <div className="container font-sans text-black text-xl">
            <br></br>
            <h5 >Hello!</h5>
            <h6> 
            <br></br>
            Any wrong information or bugs, please let us know! text us at @coursefinder_epfl on telegram
            <br></br><br></br>

            We are looking for: 
            <ul>
                <li>* engineer</li>
                <li>* product designer</li>
                <li>* operational support</li>
            </ul>
            Text us at @coursefinder_epfl on telegram if you are interested in joining us!
            <br></br>
            
            </h6>
            <br></br>
            <b>FAQ</b>
            <h6 className=''><b>1. We already evaluate the courses on ISA and Moodle, why this site? </b></h6>
                <p className='md:mr-20'>
                    At the beginning of every semester, student group chats are flooded with questions like "is xxx-course good?", and responses are washed away
                    by subsequent questions. Looking through the chat history is not the best way to find information. We attempt to aggregate student feedbacks and make it more accessible.
            <br></br>
                    This site is different from the EPFL feedbacks because the feedbacks are intended to help students better choose their 
                    classes, not for professors to improve their teaching. Although, these two aspects have a lot in common and we hope that we will have a chance to
                    to merge in the future. 
            </p> 
                

            <h6><b>2. Why can't I log in with Tequila? </b></h6>
            Because it's not an official EPFL project, we don't have the permission to use Tequila.


            <h6><b>3. What can I do to make this site better? </b></h6>
            Write a review for us! They will forever be anonymous. Please be kind, honest, and detailed when writing in reviews :D thanks in advance. 

            <h6><b>4. Will there be a French version? </b></h6>
            Maybe...Depending on how many people request it. Feel free to write your reviews in French if you are more comfortable with it! 
            <br></br><br></br>
        </div>
)
}
export default About