import { IBucketCategory } from "../types"

function h_bucketCategoryColor(categories: IBucketCategory[]) {
    categories.forEach((category) => {
        switch (category.categoryColor) {
            case 'green':
                category.titleColor = "text-green-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-green-400/30 m-2 border-2 rounded-lg"
                break
            case 'yellow':
                category.titleColor = "text-yellow-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-yellow-400/30 m-2 border-2 rounded-lg"
                break
            case 'blue':
                category.titleColor = "text-blue-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-blue-400/30 m-2 border-2 rounded-lg"
                break
            case 'red':
                category.titleColor = "text-red-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-red-400/30 m-2 border-2 rounded-lg"
                break
            case 'lime':
                category.titleColor = "text-lime-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-lime-400/30 m-2 border-2 rounded-lg"
                break
            case 'orange':
                category.titleColor = "text-orange-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-orange-400/30 m-2 border-2 rounded-lg"
                break
            case 'rose':
                category.titleColor = "text-rose-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-rose-400/30 m-2 border-2 rounded-lg"
                break
            case 'amber':
                category.titleColor = "text-amber-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-amber-400/30 m-2 border-2 rounded-lg"
                break
            case 'sky':
                category.titleColor = "text-sky-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-sky-400/30 m-2 border-2 rounded-lg"
                break
            case 'black':
                category.titleColor = "text-black-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-zinc-800/30 m-2 border-2 rounded-lg"
                break
            case 'emerald':
                category.titleColor = "text-emerald-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-emerald-400/30 m-2 border-2 rounded-lg"
                break
            case 'stone':
                category.titleColor = "text-stone-400 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-stone-400/30 m-2 border-2 rounded-lg"
                break
            default:
                category.titleColor = "text-gray-600 text-xl md:text-4xl font-bold px-2 pt-0.5"
                category.borderColor = "border-gray-400/30 m-2 border-2 rounded-lg"
        }
    })
    return categories
}

export default h_bucketCategoryColor;