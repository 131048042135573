import axios from "axios";
var axiosConfig;

if (process.env.NODE_ENV === "development") {
  axiosConfig = axios.create({
    baseURL: "http://localhost:4000",
    withCredentials: true,
  });
} else {
  axiosConfig = axios.create({
    baseURL: process.env.baseURL,
    withCredentials: true,
  });
}

export default axiosConfig;
