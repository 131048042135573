

export default function Button(props: { text: string, id: string, onClick: () => void, className?: string }) {
    const { text, id, className, onClick } = props;
    return (
        <button id={id} className={`${className}`} 
        onClick={onClick}>
            {text}
        </button>
    )
}
