import { useEffect } from "react";
import Button from "../components/Button";
import axiosConfig from "../axiosConfig";
import { useState } from "react";
import { IBucketCategory } from "../types";
import BucketCategory from "../components/BucketCategory";
import h_bucketCategoryColor from "../helperFunctions/h_bucketCategoryColor";

export function BucketlistPage() {
    const [bucketListCategory, setBucketListCategory] = useState<IBucketCategory[]>([]); // [IChecklistCard, IChecklistCard, ...] 
    const [proposalName, setProposalName] = useState('');
    const [proposalDescription, setProposalDescription] = useState('');
    const [proposalEmail, setProposalEmail] = useState('');
    const [totalScore, setTotalScore] = useState(0);
    useEffect(()=>{
         axiosConfig.get('/getBucketList')
        .then((res)=>{
            console.log("res.data is", res.data)
                setBucketListCategory(h_bucketCategoryColor(res.data))
            })
    }, [])

    useEffect(() => {
        let totalScore = 0;
        bucketListCategory.forEach((category) => {
             category.items.forEach(async (item) => {
                if (localStorage.getItem(item._id) === 'checked') {
                   totalScore += item.itemScore
                    checkFromStorage(item._id)
                }
            })
        })
        setTotalScore(totalScore)
        
    }, [bucketListCategory])


    // check from local storage, doens't send data to database, calculate score
    const checkFromStorage =  (id: string) => {
        const checkbox = document.getElementById(`checkbox-${id}`) as HTMLInputElement
        checkbox.checked = true; 
        document.getElementById(`card-${id}`).className = 'border-2 border-gray-800 bg-gray-100 grid-span-1 rounded shadow-sm'
    } 

    // send data to database, used by onClick function 
    const checkOneItem = async (props:{objectID?: string, itemScore: number, action?: string}) => {
        await axiosConfig.post('/checkOneItem', {
            objectID: props.objectID,
            action: props.action
        }).then((res) => setBucketListCategory(h_bucketCategoryColor(res.data)))
        if (props.action === 'increase'){
                localStorage.setItem(props.objectID, 'checked')
                setTotalScore(totalScore + props.itemScore)
        } else {
                localStorage.removeItem(props.objectID)
            setTotalScore(totalScore - props.itemScore)
        }
    };

    const submitBucketItemProposal = () => {
        axiosConfig.post('/submitBucketItemProposal', { 
            proposalName: proposalName, 
            proposalDescription: proposalDescription,
            proposalEmail: proposalEmail
        }).then( (res)=>{
            if (res.data.error){
                alert(res.data.error)
            } else {
                setProposalName('')
                setProposalDescription('')
                setProposalEmail('')
            }
        })
    }
    return (
        <div className="">
            <div id='Modal' className=" hidden w-full h-full bg-zinc-500/50 z-10 fixed flex justify-center " >
                <div id='proposeModalBox' className="border-4 bg-white m-2 p-1 md:m-8 md:p-4 w-2/3 md:w-1/2 h-3/5 lg:h-1/2 rounded-lg">
                    <h3 id='proposeTitle' className="mb-2 text-base font-medium text-gray-900 dark:text-white">What would you like to propose? </h3>
                    <div id="propose1">
                        <label htmlFor="proposeWhatInput" id="proposeWhatLabel" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">What activity?</label>
                        <input type="what" name="what" id="proposeWhatInput" className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="an activity, a place, must-eat, hike etc..." 
                        onChange={(e)=>{setProposalName(e.target.value)}}
                        required/>
                    </div>
                    <div id="propose2">
                        <label htmlFor="proposeWhyInput" id="proposeWhyLabel" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Briefly explain why? </label>
                        <input type="why" name="why" id="proposeWhyInput" className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="unique, romantic, iconic, yummy..." 
                        onChange={(e) => { setProposalDescription(e.target.value) }}
                        required/>
                    </div>
                    
                    <div id='propose3'>
                        <label htmlFor="proposeEmailInput" id = "proposeEmailLabel" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email? (optional) </label>
                        <input type="email" name="email" id="proposeEmailInput" className="bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="if you want to be notified when selected..." 
                        onChange={(e) => { setProposalEmail(e.target.value)}}
                        />
                    </div>
                    <Button id='submitButton' className="bg-blue-400 hover:bg-blue-800 hover:border-2 rounded text-xl p-2 text-white" text="submit" onClick={()=>{submitBucketItemProposal()}}></Button>
                </div>
                <div id='thankyou' className="hidden border-4 bg-white m-8 p-4 w-2/3 md:w-1/2 h-1/2 rounded-lg">
                    Thank you very much for proposing an new item. We will get back to you :D
                </div>
                {
                    window.onclick = function (event) {   
                        if (event.target.id === 'submitButton'){ // when submitted, the thank you page will show, and upon clicking it will disappear
                            document.getElementById('thankyou').className = 'visible border-4 bg-white m-8 p-4 w-2/3 md:w-1/2 h-1/2 rounded-lg';
                            document.getElementById('proposeModalBox').className = 'hidden';
                        }              
                        else if (event.target.id.includes('propose')) { // if inputting, clicking the modal, nothing will happen
                            return 
                        } else { //clicking the background will make the modal disappear
                            document.getElementById('Modal').className = 'hidden';
                        }
                    }
                }
            </div>
        
            <div className="grid grid-cols-12 z-0 m-0.5 md:mx-8">
                <h1 className="col-span-9 md:col-span-10 text-lg md:text-xl lg:text-4xl font-extrabold text-gray-900 ">Your ultimate EPFL Bucketlist &nbsp; 
                ⭐<span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r to-rose-600 from-orange-400"> {totalScore}</span>
                </h1>
                <button id="proposeButton" className="text-sm col-span-3 md:col-span-2 md:text-lg m-2 bg-rose-500 hover:bg-rose-700 text-white font-bold rounded " onClick={() => { 
                    document.getElementById('Modal').className = 'visible w-full h-full bg-zinc-500/50 z-10 fixed flex justify-center'
                    document.getElementById('proposeModalBox').className = 'border-4 bg-white m-2 p-1 md:m-8 md:p-4 w-2/3 md:w-1/2 h-3/5 lg:h-1/2 rounded-lg';
                    document.getElementById('thankyou').className = 'hidden'
                }} >Propose an item</button>
            </div>
            <div id='Categories' className="m-0.5 md:mx-8" >
                {
                    bucketListCategory.length === 0 ? <div>loading...</div> :
                        bucketListCategory.map((category, index) => {
                            return (
                                <BucketCategory key={category._id} categoryName={category.categoryName} categoryBorderColor={category.borderColor} categoryTitleColor={category.titleColor} cards={category.items} onClickFunc={checkOneItem} ></BucketCategory>
                            )
                        })
                }
            </div>
            
        </div>
    )
}


