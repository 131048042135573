import { IBucketItem } from "../types"
import BucketItemCard from "./BucketItemCard"
export default function BucketCategory(props:{categoryName: string, categoryTitleColor:string, categoryBorderColor: string, cards: IBucketItem[], onClickFunc: (props) => void}) {
    const {categoryName, categoryTitleColor, categoryBorderColor, cards, onClickFunc} = props;
    //
    return (
        <div className={categoryBorderColor}>
            <h2 className={categoryTitleColor}>{categoryName}</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-2 p-2 ">
                {
                    cards === null ? <div>loading...</div> :
                        cards.map((item, index) => {
                            return (
                                <BucketItemCard key={index} onClickFunc={onClickFunc} card={item}></BucketItemCard>
                            )
                        })
                }
            </div>
        </div>
        
    )
}