import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CourseTable from "../components/CourseTable";
import axiosConfig from "../axiosConfig";
import {h_coursesCategorizeColor} from "../helperFunctions/h_courseCategorizeColor";

function Home() {
  const [allCourses, setAllCourses] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    axiosConfig.get("/courseInfoCombined").then((res) => {
      if (res.status !== 200) {
        navigate("/login");
      } else {
        setAllCourses(h_coursesCategorizeColor(res.data));
      }
    });
  }, [navigate]);

  return <CourseTable initialCourses={allCourses} />;
}

export default Home;
