import React, {useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import axiosConfig from "../axiosConfig";
import { ILocationProps } from '../types';

function LoginPage() {
    const [firstname, setFirstName ]= useState('')
    const [lastname, setLastName ]= useState('')
    const [sciper, setSciper] = useState('')
    const {getLoggedIn, persist, setPersist} = useContext(AuthContext)
    let navigate = useNavigate();
    const location = useLocation() as unknown as ILocationProps
    const from = location.state?.from?.pathname || "/";

    /* login and check if the user is correct */
    const submitUserLogin = async (e) => {
        e.preventDefault()
        try{
            await axiosConfig.post('/login', {
                firstname: firstname.trim(),
                lastname: lastname.trim(),
                sciper: sciper
            }, {withCredentials: true})
            .then(async (res) => {
                if (res.status === 200) {
                    await getLoggedIn()
                    navigate(from, {replace :true})
                }
            })
        } catch(e:any) {
            console.log(e)
            alert("error logging in"+e)
        }  
    }
    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])


    return (
        <div className='flex justify-center items-center h-screen bg-gray-200'>
            <form>
                <div className='w-full text-lg grid  bg-white p-10 pt-14 rounded'>
                    <div className='text-[50px] mb-3'>🥳</div>
                    <div className='text-2xl'>Welcome to EPFL CourseFinder!  </div>
                    <span className='text-sm'> We need to verify that you are an EPFL student </span> 
                    
                    <label>First Name</label>
                    <input className='bg-gray-100 border' onChange={(e)=>{setFirstName(e.target.value)}}></input>
               
                    <label>Last Name</label>
                    <input className='bg-gray-100 border' onChange={(e)=>{setLastName(e.target.value)}}></input>

                    <label>Sciper Number</label>
                    <input className='bg-gray-100 border' onChange={(e) => {setSciper(e.target.value) }}></input>

                    <div className='text-base'>
                        <input id="remember" type='checkbox' onChange={togglePersist}></input>
                        <label htmlFor="remember" className='pl-2'>Remember me</label>
                    </div>
                    
                    <button className='bg-blue-400  rounded mt-3' onClick={ submitUserLogin }> Log in </button>
                    <div className="text-gray-400 mt-3 text-xs"> have trouble? exchange students, alums without sciper? </div>
                    <div className="text-gray-400 text-xs"> please text telegram @coursefinder_epfl </div>
                </div>
            </form>
        </div>
        
    )
}

export default LoginPage; 
