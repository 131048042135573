export {}

export function SettlePage() {
    return (
        <SettleTonyMate></SettleTonyMate>
    )
}

function SettleTonyMate() {
    return (
        <div>

        </div>
    )
}

function SettleMigrosSandwich(){

}