import React, { useState, useEffect, ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import "./CourseTableStyle.css";
import { CompareCourse, CompareCourseFunc } from "./CompareCourse";
import FilterBar from "./FilterBar";
import {List, AutoSizer, CellMeasurerCache, CellMeasurer, WindowScroller,} from "react-virtualized";
import ResizeObserver from "rc-resize-observer";
import { ICourse } from "../types";
import { CommentList } from "./CommentList";

function CourseTable(props: { initialCourses: ICourse[] }): ReactElement {
  const { initialCourses } = props;
  const [displayedCourses, setDisplayedCourses] = useState(initialCourses);
  const [section, setSection] = useState("");
  const [exam, setExam] = useState("");
  const [term, setTerm] = useState("");
  const [credit, setCredit] = useState("0");
  const [searchTerms, setSearchTerms] = useState("");
  const location = useLocation();

  // Keep track of the column sort order
  const [sortOrder, setSortOrder] = useState({
    column: "courseCode",
    ascending: true,
  });

  // Update displayed courses
  useEffect(() => {
    // Get ICourse comparison function
    const compFuncDict: { [key: string]: CompareCourseFunc } = {
      courseCode: (a, b) => a.courseCode.localeCompare(b.courseCode),
      avgRating: (a, b) => a.avgRating - b.avgRating,
      avgProfRating: (a, b) => a.avgProfRating - b.avgProfRating,
      avgDifficulty: (a, b) => a.avgDifficulty - b.avgDifficulty,
      avgWorkload: (a, b) => a.avgWorkload - b.avgWorkload,
    };
    const compFuncAscending = compFuncDict[sortOrder.column];
    const compFunc: CompareCourseFunc = sortOrder.ascending
      ? compFuncAscending
      : (a, b) => -compFuncAscending(a, b);
    // Update ICourse list
    setDisplayedCourses(
      // Filter-out courses
      initialCourses
        .filter((ICourse) => {
          const searchMatch =
            ICourse.courseName
              .toLowerCase()
              .includes(searchTerms.toLowerCase()) ||
            ICourse.profNames
              .join(" ")
              .toLowerCase()
              .includes(searchTerms.toLowerCase()) ||
            ICourse.courseCode.toLowerCase().includes(searchTerms.toLowerCase());
          return (
            (ICourse.term.includes(term) || term === "") &&
            (ICourse.courseCode.includes(section) || section === "") &&
            (ICourse.credit === Number(credit) || credit === "0") &&
            (searchTerms === "" || searchMatch) && // either the search is empty or it matches
            (ICourse.exam === exam || exam === "")
          );
        })
        // Sort courses
        .sort((courseA, courseB) => {
          return CompareCourse(courseA, courseB, compFunc);
        })
    );
  }, [section, term, credit, exam, searchTerms, initialCourses, sortOrder]);

  const renderRow = (props: {
    index: any;
    key: any;
    style: any;
    parent: any;
  }) => {
    const { index, key, style, parent } = props;
    const ICourse = displayedCourses[index];
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div key={key} ref={registerChild as any} style={style}>
            <ResizeObserver onResize={measure}>
              <div className="accordion">
                <label
                  htmlFor={index}
                  className="grid grid-cols-12 w-full p-2 font-medium text-black border border-b-0 border-gray-200 rounded-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100"
                >
                  <div className="col-span-3 font-sm md:col-span-1">
                    <a
                      href={ICourse.courseUrl}
                      className="text-blue-700 underline"
                    >
                      {ICourse.courseCode}
                    </a>
                  </div>
                  <div className="col-span-9 font-sm md:col-span-7">
                    {ICourse.courseName}
                  </div>
                  <div className={`${ICourse.avgRatingColor}`}>
                    {ICourse.avgRating ? ICourse.avgRating : "NA"}
                    <span className="text-xs">
                      {" "}
                      ({ICourse.comments ? ICourse.comments.length : null})
                    </span>{" "}
                  </div>
                  <div className={`${ICourse.avgProfRatingColor}`}>
                    {ICourse.avgProfRating ? ICourse.avgProfRating : "NA"}{" "}
                  </div>
                  <div className={`${ICourse.avgDifficultyColor}`}>
                    {ICourse.avgDifficulty ? ICourse.avgDifficulty : "NA"}{" "}
                  </div>
                  <div className={`${ICourse.avgWorkloadColor}`}>
                    {ICourse.avgWorkload ? ICourse.avgWorkload : "NA"}
                    <span className="text-xs"> ({ICourse.credit})</span>
                  </div>
                </label>
                <input
                  type="checkbox"
                  className="absolute opacity-0 max-h-0 max-w-0"
                  id={index}
                  name="accordion"
                ></input>

                <div className="accordion-body p-0 mx-lg-5 my-3">
                  <div>
                    Professor:{" "}
                    {ICourse.profNames.map((profName, index) => {
                      return (
                        <a
                          key={index}
                          className="hover:underline text-blue-600"
                          href={ICourse.profUrls[index]}
                        >
                          {" "}
                          {profName}
                        </a>
                      );
                    })}
                  </div>

                  <CommentList ICourse={ICourse} />
                  <div className="flex justify-end">
                    <Link
                      className="p-2 mt-1 bg-blue-500 hover:bg-blue-700 text-white rounded-md"
                      to={`/rate?courseCode=${ICourse.courseCode}`}
                      state={{ from: location }}
                    >
                      Rate me!
                    </Link>
                  </div>
                </div>
              </div>
            </ResizeObserver>
          </div>
        )}
      </CellMeasurer>
    );
  };

  const cache = new CellMeasurerCache({
    defaultHeight: 37, // tune as estimate for unmeasured rows
    fixedWidth: true,
  });

  return (
    <>
      <FilterBar
        setSection={setSection}
        setTerm={setTerm}
        setExam={setExam}
        setCredit={setCredit}
        setSearchTerms={setSearchTerms}
        searchTerms={searchTerms}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      ></FilterBar>
      <div className="pl-4 pr-4 flex-grow-1" style={{ height: "1000px" }}>
        <AutoSizer>
          {({ width }) => {
            return (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <List
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    rowCount={displayedCourses.length}
                    rowHeight={cache.rowHeight}
                    rowRenderer={renderRow}
                    scrollTop={scrollTop}
                    width={width}
                    overscanRowCount={0}
                  />
                )}
              </WindowScroller>
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
}

export default CourseTable;
