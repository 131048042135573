import { ICourse } from "../types"

export function h_coursesCategorizeColor(courses: ICourse[]) {
    const parameters: (keyof ICourse)[] = ['avgRating', 'avgWorkload', 'avgDifficulty', 'avgProfRating']
    courses.map((course: ICourse) => {
        parameters.map((element) => {
            if (course[element] && (element === 'avgRating' || element === 'avgProfRating')) {
                if (course[element] >= 4.3 && course[element] <= 5) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-teal-400'
                } else if (course[element] >= 3.5 && course[element] < 4.3) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-emerald-200'
                } else if (course[element] >= 3 && course[element] < 3.5) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-yellow-200'
                } else if (course[element] >= 2 && course[element] < 3) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-orange-200'
                } else if (course[element] >= 1 && course[element] < 2) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-rose-300'
                }
            } else if (course[element] && (element === 'avgWorkload' || element === 'avgDifficulty')) {
                if (course[element] >= 4.3 && course[element] <= 5) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-rose-300'
                } else if (course[element] >= 3.5 && course[element] < 4.3) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-orange-200'
                } else if (course[element] >= 3 && course[element] < 3.5) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-yellow-200'
                } else if (course[element] >= 2 && course[element] < 3) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-emerald-200'
                } else if (course[element] >= 1 && course[element] < 2) {
                    course[element + "Color"] = 'col-span-3 md:col-span-1 text-center rounded-lg bg-teal-200'
                }
            }
            else {
                course[element + "Color"] = 'col-span-3 md:col-span-1 bg-zinc-100 text-center rounded-lg bg-gray-200'
                // course[element] = 'NA'
            }
            return (course)
        })
    })
    return courses
}
