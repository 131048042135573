import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Logout = () => {
    const { getLoggedOut } = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        const logouthelper = async () => {
            await getLoggedOut()
            navigate("/login", { replace: true })
        }
        logouthelper()
    }, [])

    return (<div>Nothing</div>)
}

export default Logout;