import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';

/* 
TODO: to be resolved 
1. What the fuck is useDrag and useDrop?
Specifically, how do useMemo dependencies work? 
How do they relate to closures? 
https://react-dnd.github.io/react-dnd/docs/api/use-drag 

*/

const sample_data = [{ id: 1, courseCode: 'CS438', belongsToBucket: 'hesitate', credit: 3 },
{ id: 2, courseCode: 'MGT500', belongsToBucket: 'hesitate', credit: 6},
{ id: 3, courseCode: 'CH-234', belongsToBucket: 'hesitate', credit: 8}]

function MyList() {
    const [items, setItems] = useState(sample_data);

    const returnCoursesForBucket = (bucketName) => {
        return items
            .filter((course) => course.belongsToBucket === bucketName)
            .map((item, index) =>{
                return <DraggableCourse course={item} key={index} id = {item.id} changeBucketFunc={changeBucket} />   
            })
    }

    const changeBucket = (courseId, bucketName) => {
        console.log("called to changeBucket ", bucketName)
        setItems((prevState) => { 
            return prevState.map(course =>{
                console.log("change Bucket id" ,course.id, courseId)
                if (course.id === courseId) {
                    return {...course, belongsToBucket: bucketName}
                }
                return course
            })
        })
    }
  
    return ( 
        <div className='m-10 md:m-5 '>
            {JSON.stringify(items)}
            <Bucket title="Taking For Sure" className=" flex flex-row min-h-[8rem] mb-4 border-2 border-slate-300" handlerName = "certain">
                {returnCoursesForBucket('certain')}
               
            </Bucket>

            <Bucket title="Hesitating" className="min-h-[8rem] border-2 border-slate-900" handlerName="hesitate">
                {returnCoursesForBucket('hesitate')}
            </Bucket>
            
        </div>
     );
}

export default MyList;

const DraggableCourse = ({course, id, changeBucketFunc}) => {
    const [{ isDragging }, drag] = useDrag(() => {
        return {
            type: "course",
            item: { courseCode: course.courseCode, id: id },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult() as dropState
                if (dropResult && dropResult.name) {
                
                    changeBucketFunc(id, dropResult.name)
                }
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }
    }, [course, id]
    );
    console.log(isDragging, course.courseCode )

    return (
        <div className={`w-${course.credit*1} m-1 md:m-3 h-12 border-2 border-slate-600 bg-orange-50`}
        ref = {drag}
        > 
            <span>{course.courseCode}</span>
            
        </div>
    );
}

type dropState = {name: string}

const Bucket = ({ children, className, handlerName, title }) => {
    const [, drop] = useDrop(() => ({
        accept: 'course',
        drop: ()=> ({ name: handlerName })
        ,
    }), [title]);

    return (
        <div ref={drop} className={className}>
            {title}
            {children}
        </div>
    )
}