import { createContext, useState, useEffect } from "react";
import axiosConfig from "../axiosConfig.tsx";

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [role, setRole] = useState(undefined); 

  async function getLoggedIn() {

    const loggedInRes = await axiosConfig.get(
      "/loggedIn"
    )
    setLoggedIn(loggedInRes.data.loggedIn);
    setRole(loggedInRes.data.role);
  }

  async function getLoggedOut() {
    try {
      setLoggedIn({})
      setRole({})
      await axiosConfig.get("/logout");
    } catch (error) {
      // won't show to the clients what's the error
      console.log("AuthContextProvider Error " )
    }
  }

  useEffect(() => {
    getLoggedIn();
  }, []);

  return (
    <AuthContext.Provider value={{ loggedIn, role, getLoggedIn, getLoggedOut }}>
      {props.children}
    </AuthContext.Provider>
  )
}


export default AuthContext;
export { AuthContextProvider };