const fullSectionName = [
    'Applied Mathematics',
    'Applied Physics',
    'Architecture',
    'Chemical Engineering',
    'Chemical Engineering and Biotechnology',
    'Chemistry',
    'Chemistry and Chemical Engineering',
    'Civil Engineering',
    'Communication Systems',
    'Communication Systems - master program',
    'Computational science and Engineering',
    'Computer Science',
    'Computer Science - Cybersecurity',
    'Data Science',
    'Design Together ENAC',
    'Digital Humanities',
    'Electrical and Electronics Engineering',
    'Energy Science and Technology',
    'Environmental Sciences and Engineering',
    'Financial engineering',
    'Humanities and Social Sciences Program',
    'Life Sciences Engineering',
    'Management, Technology and Entrepreneurship',
    'Materials Science and Engineering',
    'Mathematics',
    'Mathematics - master program',
    'Mechanical Engineering',
    'Micro- and Nanotechnologies for Integrated Systems',
    'Microengineering',
    'Molecular & Biological Chemistry',
    'Neuro-X Section',
    'Nuclear engineering',
    'Physics',
    'Physics - master program',
    'Quantum Science and Engineering',
    'Robotics',
    'Statistics',
    'Sustainable Management and Technology'
]

const sectionCode = [
    "AR",
    "BIO",
    "BIOENG",
    "CH",
    "CIVIL",
    "COM",
    "CS",
    "ChE",
    "DH",
    "EE",
    "ENG",
    "ENV",
    "ETH",
    "FIN",
    "HUM",
    "MATH",
    "ME",
    "MGT",
    "MICRO",
    "MSE",
    "NX",
    "PENS",
    "PHYS",
    "QUANT"]

const PropedeuticsSections = [
    'Architecture',
    'Chemistry and Chemical Engineering',
    'Civil Engineering',
    'Communication Systems',
    'Computer Science',
    'Electrical and Electronics Engineering',
    'Environmental Sciences and Engineering',
    'Humanities and Social Sciences Program',
    'Life Sciences Engineering',
    'Materials Science and Engineering',
    'Mathematics',
    'Mechanical Engineering',
    'Microengineering',
    'Physics'
]

const BASections = [
    'Architecture',
    'Chemical Engineering',
    'Chemistry and Chemical Engineering',
    'Chemistry',
    'Civil Engineering',
    'Communication Systems',
    'Computer Science',
    'Design Together ENAC',
    'Electrical and Electronics Engineering',
    'Environmental Sciences and Engineering',
    'Humanities and Social Sciences Program',
    'Life Sciences Engineering',
    'Materials Science and Engineering',
    'Mathematics',
    'Mechanical Engineering',
    'Microengineering',
    'Physics'
]

const MASections = [
    'Applied Mathematics',
    'Applied Physics',
    'Architecture',
    'Chemical Engineering and Biotechnology',
    'Civil Engineering',
    'Communication Systems - master program',
    'Computational science and Engineering',
    'Computer Science',
    'Computer Science - Cybersecurity',
    'Data Science',
    'Digital Humanities',
    'Electrical and Electronics Engineering',
    'Energy Science and Technology',
    'Environmental Sciences and Engineering',
    'Financial engineering',
    'Humanities and Social Sciences Program',
    'Life Sciences Engineering',
    'Management, Technology and Entrepreneurship',
    'Materials Science and Engineering',
    'Mathematics - master program',
    'Mechanical Engineering',
    'Micro- and Nanotechnologies for Integrated Systems',
    'Microengineering',
    'Molecular & Biological Chemistry',
    'Neuro-X Section',
    'Nuclear engineering',
    'Physics - master program',
    'Quantum Science and Engineering',
    'Robotics',
    'Statistics',
    'Sustainable Management and Technology'
]


export { fullSectionName, sectionCode, MASections, BASections, PropedeuticsSections }