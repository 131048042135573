import { ICourse } from "../types";
import { ReactElement, memo } from "react";

export const CommentList = memo(CommentBlock) // so that when props don't change, this comment list won't rerender
function CommentBlock(props: { ICourse: ICourse }): ReactElement{
    const { ICourse } = props;
    if (!ICourse.comments.length) {
        return <div>No reviews yet 😢 </div>;
    }
    return (
        <div>
            <div className="flex flex-row">
                <div className="basis-1/2 border-zinc-300 pl-3 pr-3 bg-zinc-300 border">
                    Comments
                </div>
                <div className="basis-1/2 border-zinc-300 pl-3 pr-3 bg-zinc-300 border">
                    Study Tips
                </div>
            </div>

            <div className="grid grid-cols-2">
                <div className="border">
                    {ICourse.comments.map((v, i) => {
                        if (i % 2 === 0) {
                            return (
                                <div
                                    key={i}
                                    className="col-span-1 pl-4 pr-4 text-gray-600 bg-white"
                                    id={`comment ${i}`}
                                >
                                    {v.comment}
                                    {v.legacy ? (
                                        <span className="text-amber-800 text-xs">
                                            <br></br> *sourced from the web{" "}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={i}
                                    className="col-span-1 pl-4 pr-4 text-gray-600 bg-zinc-100"
                                    id={`comment ${i}`}
                                >
                                    {v.comment}
                                    {v.legacy ? (
                                        <span className="text-amber-800 text-xs">
                                            <br></br> *sourced from the web{" "}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            );
                        }
                    })}
                </div>
                <div className="border">
                    {ICourse.studytips.map((v, i) => {
                        if (i % 2 === 0) {
                            return (
                                <div
                                    key={i}
                                    className="col-span-1 pl-4 pr-4 text-gray-600 bg-white"
                                    id={`studytip ${i}`}
                                >
                                    {v.studyTip}
                                    {v.legacy ? (
                                        <span className="text-amber-800 text-xs">
                                            <br></br> *sourced from the web{" "}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={i}
                                    className="col-span-1 pl-4 pr-4 text-gray-600 bg-zinc-100"
                                    id={`studytip ${i}`}
                                >
                                    {v.studyTip}
                                    {v.legacy ? (
                                        <span className="text-amber-800 text-xs">
                                            <br></br> *sourced from the web{" "}
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
}; 
